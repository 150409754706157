<template>
  <a-skeleton active :loading="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="24"><span>编码：</span>{{ formItem.deviceGatewayNo || '-' }}</a-col>
        <a-col :span="24"><span>中文名称：</span> {{ formItem.cnName || '-' }}</a-col>
        <a-col :span="24"><span>英文名称：</span> {{ formItem.enName || '-' }}</a-col>
        <a-col :span="24"><span>用户名：</span> {{ formItem.username || '-' }}</a-col>
        <a-col :span="24"><span>密码：</span> {{ formItem.password || '-' }}</a-col>
        <a-col :span="24"><span>URL：</span> {{ formItem.url || '-' }}</a-col>
        <a-col :span="24"><span>SIM卡：</span>{{ formItem.sim || '-' }}</a-col>
        <a-col :span="24"
          ><span>供电方式：</span>
          <span v-for="item in powerTypeList">{{ item.value === formItem.powerType ? item.label : '' }}</span>
        </a-col>
        <a-col :span="24"
          ><span>安装时间：</span
          >{{ formItem.installTime ? moment(formItem.installTime).format('YYYY-MM-DD') : '-' }}</a-col
        >
        <a-col :span="24"><span>安装厂家：</span> {{ formItem.installFirm || '-' }}</a-col>
        <a-col :span="24"
          ><span>厂家类型：</span>
          <span v-for="item in companyTypeList">{{ item.value === formItem.type ? item.label : '' }}</span></a-col
        >
        <a-col :span="24"><span>安装人员：</span> {{ formItem.installUser || '-' }}</a-col>
        <a-col :span="24"><span>安装地点：</span> {{ formItem.installLocation || '-' }}</a-col>
        <a-col :span="24"
          ><span style="width: 95px;">采集频率(秒)：</span> {{ formItem.collectFrequency || '-' }}</a-col
        >
      </a-row>
    </div>
  </a-skeleton>
</template>

<script>
import { gatewayFormVO, companyTypeList, powerTypeList } from './common/common'
import SERVICE_URLS from '@/api/service.url'
import moment from 'moment'

export default {
  name: 'gatewayDetail',
  components: {},
  data() {
    return {
      ifSub: false,
      formItem: gatewayFormVO(),
      companyTypeList: companyTypeList(),
      powerTypeList: powerTypeList(),
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      tableData: [],
    }
  },
  methods: {
    moment,
    loadData(id) {
      this.$http(this, {
        url: SERVICE_URLS.equipment2.gatewayApi.view,
        params: { id },
        noTips: true,
        success: (data) => {
          Object.assign(this.formItem, data.body)
        },
      })
    },
  },
}
</script>

<style scoped>
.text-title {
  font-size: 17px;
  font-weight: 700;
  font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
}
.ant-form-item {
  margin-bottom: 0px;
}
</style>
