<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form" layout="vertical" style="clear: both; height: 556px;">
      <a-form-item label="网关编号">
        <a-input
          v-decorator="['deviceGatewayNo', { rules: [{ required: true, message: '网关编号不能为空' }] }]"
          placeholder="请输入网关编号"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="厂家类型">
        <a-select
          showSearch
          v-decorator="['type', { rules: [{ required: true, message: '类型不能为空' }] }]"
          placeholder="请选择厂家类型"
        >
          <a-select-option
            v-for="(item, index) in companyTypeList"
            :key="index"
            :value="item.value"
            :label="item.label"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="中文名称">
        <a-input
          v-decorator="['cnName', { rules: [{ required: true, message: '中文名称不能为空' }] }]"
          placeholder="请输入中文名称"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="英文名称">
        <a-input
          v-decorator="['enName', { rules: [{ required: true, message: '英文名称不能为空' }] }]"
          placeholder="请输入英文名称"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="用户名">
        <a-input v-decorator="['username']" placeholder="请输入用户名"></a-input>
      </a-form-item>
      <a-form-item label="密码">
        <a-input v-decorator="['password']" placeholder="请输入密码"></a-input>
      </a-form-item>
      <a-form-item label="URL">
        <a-input v-decorator="['url', { rules: [{ required: true, message: 'URL不能为空' }] }]" placeholder="请输入URL">
        </a-input>
      </a-form-item>
      <a-form-item label="SIM卡">
        <a-input v-decorator="['sim']" placeholder="请输入SIM卡"></a-input>
      </a-form-item>
      <a-form-item label="供电方式">
        <a-select showSearch allowClear v-decorator="['powerType']" placeholder="请选择供电方式">
          <a-select-option v-for="(item, index) in powerTypeList" :key="index" :value="item.value" :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="安装时间">
        <a-date-picker
          showTime
          v-decorator="['installTime']"
          format="YYYY-MM-DD"
          style="width: 100%;"
          placeholder="请选择安装时间"
        ></a-date-picker>
      </a-form-item>
      <a-form-item label="安装厂家">
        <a-input v-decorator="['installFirm']" placeholder="请输入安装厂家"></a-input>
      </a-form-item>
      <a-form-item label="安装人员">
        <a-input v-decorator="['installUser']" placeholder="请输入安装人员"></a-input>
      </a-form-item>
      <a-form-item label="安装地点">
        <a-input v-decorator="['installLocation']" placeholder="请输入安装地点"></a-input>
      </a-form-item>
<!--      <a-form-item label="采集频率(秒)">-->
<!--        <a-input-->
<!--          v-decorator="['collectFrequency', { rules: [{ required: true, message: '采集频率不能为空' }] }]"-->
<!--          placeholder="请输入采集频率"-->
<!--        ></a-input>-->
<!--      </a-form-item>-->
    </a-form>
  </a-spin>
</template>

<script>
  import { gatewayFormVO, companyTypeList, powerTypeList } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'

  export default {
    name: 'GatewayForm',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        companyTypeList: companyTypeList(),
        spinShow: false,
        formItem: gatewayFormVO(),
        form: this.$form.createForm(this),
        powerTypeList: powerTypeList()
      }
    },
    computed: {
      //获取当前单位
      currentOrg () {
        return this.$store.getters.currentOrg
      },
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    created () {
    },
    methods: {
      setVOFields (values) {
        Object.assign(this.formItem, values)
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          cnName: this.formItem.cnName,
          deviceGatewayNo: this.formItem.deviceGatewayNo,
          enName: this.formItem.enName,
          password: this.formItem.password,
          type: this.formItem.type,
          url: this.formItem.url,
          username: this.formItem.username,
          sim: this.formItem.sim,
          powerType: this.formItem.powerType,
          installTime: this.formItem.installTime ? moment(this.formItem.installTime) : null,
          installFirm: this.formItem.installFirm,
          installUser: this.formItem.installUser,
          installLocation: this.formItem.installLocation,
        })
      },
      loadData (id) {
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.equipment2.gatewayApi.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setFields(data.body)
            })
            this.spinShow = false
          }
        })
      },
      submitAdd () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.equipment2.gatewayApi.create,
              noTips: true,
              data: this.formItem,
              success: (data) => {
                this.formItem = gatewayFormVO()
                this.$emit('addSuccess')
                this.form.resetFields()
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('addError')
                this.form.resetFields()
                this.spinShow = false
              }
            })
          } else {
            this.spinShow = false
          }
        })
      },
      submitEdit () {
        this.spinShow = true
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.equipment2.gatewayApi.update,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              noTips: true,
              success: (data) => {
                this.$emit('editSuccess', data.body)
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('editError')
                this.spinShow = false
              }
            })
          } else {
          }
        })
      },
      cancel () {
        this.sup_this.cancel()
      }
    }
  }
</script>

<style scoped>
  .ant-row {
    width: 30%;
    float: left;
    margin-left: 3%;
  }
</style>
